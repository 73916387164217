import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Input from '../elements/Input';
import Button from '../elements/Button';

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool
}

const defaultProps = {
  ...SectionProps.defaults,
  split: false
}

const FUNCTION_URL = 'https://api.autostream.live';

const Cta = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {

  const outerClasses = classNames(
    'cta section center-content-mobile reveal-from-bottom',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'cta-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
    split && 'cta-split'
  );  

  return (
    <section
      {...props}
      className={outerClasses}
    >
    <form id="pre-register-form">
      <div className="container">
        <div
          className={innerClasses}
        >
          <div className="cta-slogan">
            <h3 className="m-0">
              Want to stay updated?
              </h3>
          </div>
            <div className="cta-slogan">
              <Input id="pre-register-form-input" type="email"
                label="Subscribe" labelHidden
                hasIcon="right" placeholder="Email" required></Input>
            </div>
            <div className="cta-action">
              <Button onClick={(e) => {
                e.preventDefault();
                let form = document.getElementById("pre-register-form");
                if (form.reportValidity()) {
                  fetch(FUNCTION_URL + '/email/preregister', {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                      email: form.elements[0].value
                    })
                  }).then(res => {
                    if (res.status === 200) {
                      let form = document.getElementById("pre-register-form-input");
                      form.value = "";
                      alert("You have successfully pre-registered! Thank you!");
                    } else {
                      alert("There was an error pre-registering. Please try again later.");
                    }
                  });
                }
              }} size="md" tag="a" color="primary">
                Pre-register
              </Button>
              {/* <button hidden onSubmit={}></button> */}
            </div>
        </div>
      </div>
          </form>
    </section>
  );
}

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;